import React from 'react';
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import queryString from "query-string";
// import '../framework/css/hidden.css'
import Client from '../framework/js/api';
import IosInstructions from "./IosInstructions";
import AndroidInstructions from "./AndroidInstructions";

export default class Body extends React.Component{
    constructor(props) {
        super(props);
        this.client = new Client();
        this.state = {
            isLoaded: false,
            phoneToken: null,
            e164PhoneNumber: null,
            os: null,
            response: null,
        };

        this.props.refs.button = React.createRef();
        this.props.refs.body = this;
    }

    async componentDidMount() {
        let params = this.getQueryString();
        if (isMobile) {
            if (isAndroid) {
                this.setState({phoneToken: params.id, os: 'android'});
            } else if (isIOS) {
                this.setState({phoneToken: params.id, os: 'ios'})
            }
            return this.fetchInstructions();
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refs.phoneInput) {
            this.props.refs.phoneInput.setState({showPhoneInput: !this.state.phoneToken});
        }
        return this.fetchInstructions();
    }

    fetchInstructions = async() => {
        if (!this.state.phoneToken || this.state.isLoaded) return;

        try {
            var response = await this.client.fetchInstructions(this.state.os, this.state.phoneToken);
            this.setState({
                response: response,
                isLoaded: true,
            });
        } catch(error) {
            this.setState({phoneToken: null});
            window.dispatchEvent(new CustomEvent('error', { detail: error.message }));
        }
    }

    getQueryString() {
        return queryString.parse(window.location.search, {decode: false});
    }

    render() {
        if (!this.state.phoneToken) {
            return (
                <section id="noToken">
                    <img src="/img/dial-pad-icon.svg" width="42" height="60" alt="dialpad icon" />
                    <p>Enter Your Mobile Number to continue</p>
                    <button disabled="disabled" ref={this.props.refs.button}>Continue</button>
                </section>
            );
        } else if (!this.state.isLoaded) {
            return <section>Loading...</section>
        } else if (this.state.os === 'ios') {
            return <IosInstructions response={this.state.response} phoneToken={this.state.phoneToken}/>;
        } else if (this.state.os === 'android') {
            return <AndroidInstructions response={this.state.response}/>;
        } else {
            window.dispatchEvent(new CustomEvent('error', { detail: 'Your current device cannot be identified' }));
            return null;
        }
    }
}
