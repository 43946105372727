import React from 'react';
import Client from "../framework/js/api";
import Input from 'react-phone-number-input/input';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';

import en from 'react-phone-number-input/locale/en.json'

export default class PhoneInputForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            phoneNumber:null,
            country:'US',
            showPhoneInput: true,
        };
        this.client = new Client();

        this.phoneInput = React.createRef();
        if (this.props.refs.button.current) {
            this.props.refs.button.current.addEventListener('click', this.handleSubmit);
        }
        this.props.refs.phoneInput = this;
    }

    handleChange = (newValue) => {
        this.setState({
            phoneNumber: newValue ? parsePhoneNumber(newValue) : null,
            value: newValue
        });
    }

    updateStyle = () => {
        if (this.props.refs.button.current) {
            if (isValidPhoneNumber(this.state.value)) {
                this.props.refs.button.current.removeAttribute('disabled');
            } else {
                this.props.refs.button.current.setAttribute('disabled', true);
            }
        }
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        try {
            let response = await this.client.fetchToken(this.state.phoneNumber.number);
            this.props.refs.body.setState({
                e164PhoneNumber: this.state.value,
                phoneToken: encodeURIComponent(response.token),
            });
            this.setState({showPhoneInput: false});
        } catch(error) {
            window.dispatchEvent(new CustomEvent('error', { detail: error.message }));
        }
    }

    componentDidMount() {
        this.updateStyle();
    }

    componentDidUpdate() {
        this.updateStyle();
    }

    render() {
        if (!this.state.showPhoneInput) return null;
        return (
            <form onSubmit={this.handleSubmit} className="PhoneInputForm" >
                <div>
                    <label htmlFor="country_code">Country Prefix</label>
                    <select id="country_code" name="country_code" value={this.state.country} onChange={event => this.setState({country:event.target.value || undefined})}>
                        {getCountries().map((country) => (
                            <option key={country} value={country}>+{getCountryCallingCode(country)} {en[country]}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="phone_number">Mobile Number</label>
                    <Input id="phone_number" name="phone_number" required ref={this.phoneInput} value={this.state.value} onChange={this.handleChange} defaultCountry={this.state.country} autoFocus placeholder="(&nbsp;&nbsp;&nbsp;) &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;" />
                </div>
            </form>
        );
    }
}
