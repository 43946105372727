import React from 'react';

export default class AndroidInstructions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibilityOverride: null,
            isLoaded: this.props.response !== null,
            messageAppName: 'company app store',
        }

        if (this.state.isLoaded) {
            // If we have a nicer name for the company app store, update it
            if (this.props.response.appName) {
                this.state.messageAppName = this.props.response.appName;
            } else if (this.props.response.mdmName) {
                this.state.messageAppName = `${this.props.response.mdmName} app store`;
            }

            // Determine which instructions to display
            const {isCorporateMdm, isPlayStore, isSideLoad} = this.props.response;
            if ([isCorporateMdm, isPlayStore, isSideLoad].filter(Boolean).length > 1) { // More than one are set
                this.state.visibilityOverride = null;
            } else if (isCorporateMdm) {
                this.state.visibilityOverride = 'CORPORATE_MDM';
            } else if (isPlayStore) {
                this.state.visibilityOverride = 'PLAY_STORE';
            } else if (isSideLoad) {
                this.state.visibilityOverride = 'SIDE_LOAD';
            } else {
                this.state.visibilityOverride = 'UNDEFINED';
                window.dispatchEvent(new CustomEvent('error', { detail: 'Unable to determine deployment method.' }));
            }
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return null;
        }

        switch (this.state.visibilityOverride) {
            case 'PLAY_STORE':
                window.location.href = 'https://play.google.com/store/apps/details?id=com.trucesoftware.truce';
                return null;
            case 'SIDE_LOAD':
                window.location.href = 'https://apps.trucesoftware.com/truce';
                return null;
            case 'CORPORATE_MDM':
                return (
                    <section>
                        <p>Please complete the following steps:</p>
                        <ol>
                            <li>Open your <strong>{this.state.messageAppName}</strong> app</li>
                            <li>Search for "<strong>TRUCE</strong>"</li>
                            <li>Install the <strong>TRUCE Software</strong> app</li>
                        </ol>
                    </section>
                );
            case null:
                return (
                    <section>
                        <p>Do you have the <strong>{this.state.messageAppName}</strong> installed on your device?</p>
                        <div>
                            <button onClick={this.onCorporateMdmYes}>Yes</button>
                            <button onClick={this.onCorporateMdmNo}>No</button>
                        </div>
                    </section>
                );
            default:
                return null;
        }
    }

    onCorporateMdmYes = () => {
        this.setState({visibilityOverride: 'CORPORATE_MDM'});
    };

    onCorporateMdmNo = () => {
        this.setState({visibilityOverride: this.props.response.isSideLoad ? 'SIDE_LOAD' : 'PLAY_STORE'});
    };
}
