export default class Client {
    bindParams(url, params) {
        url = new URL(url);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return url;
    }

    async fetchInstructions(os, phoneToken) {
        let response = null;
        let data = null;
        try {
            response = await fetch(`${process.env.REACT_APP_SMART_LINK_API}/link/${os}/${phoneToken}`, {
                mode: 'cors',
            });
            data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    async fetchToken(phoneNumber) {
        let response = null;
        let data = null;
        try {
            response = await fetch(`${process.env.REACT_APP_SMART_LINK_API}/token`, {
                method: 'POST',
                body: JSON.stringify({phoneNumber: phoneNumber}),
                mode: 'cors'
            });
            data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    async fetchTruceMdmStatus(token) {
        let response = null;
        let data = null;
        try {
            response = await fetch(this.bindParams('https://ios-dev.mdm.truce.cloud/mdm/enrollmentStatus', {token: token}), {
                mode: 'cors'
            });
            data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    async setPhoneNumber(token, phoneNumber) {
        let response = null;
        let data = null;
        try {
            response = await fetch('https://ios-dev.mdm.truce.cloud/mdm/setPhone', {
                body: JSON.stringify({
                    phone_number: phoneNumber,
                    token: token
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors'
            });
            data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

}
