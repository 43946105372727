import React from 'react'

export default class IosInstructions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibilityOverride: null,
            isLoaded: this.props.response !== null,
            messageAppName: 'company app store',
        }

        if (this.state.isLoaded) {
            // If we have a nicer name for the company app store, update it
            if (this.props.response.appName) {
                this.state.messageAppName = this.props.response.appName;
            } else if (this.props.response.mdmName) {
                this.state.messageAppName = `${this.props.response.mdmName} app store`;
            }

            // Determine which instructions to display
            const {isTruceMdm, isCorporateMdm, isRedemptionCode, isSideLoad} = this.props.response;
            if (isTruceMdm && !isCorporateMdm) {
                this.state.visibilityOverride = 'TRUCE_MDM';
            } else if (!isTruceMdm && !isSideLoad && isCorporateMdm) {
                this.state.visibilityOverride = 'CORPORATE_MDM';
            } else if (isCorporateMdm) {
                this.state.visibilityOverride = null;
            } else if (isSideLoad) {
                this.state.visibilityOverride = 'SIDE_LOAD';
            } else if (isRedemptionCode) {
                this.state.visibilityOverride = 'REDEMPTION_CODE';
            } else {
                this.state.visibilityOverride = 'UNDEFINED';
                window.dispatchEvent(new CustomEvent('error', { detail: 'Unable to determine deployment method.' }));
            }
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return null;
        }

        switch (this.state.visibilityOverride) {
            case 'TRUCE_MDM':
                window.location.href = `${process.env.REACT_APP_MDM_WEBSITE_URL}?smartLinkToken=${this.props.phoneToken}`;
                return null;
            case 'SIDE_LOAD':
                window.location.href = this.props.response.sideLoadAddress;
                return null;
            case 'REDEMPTION_CODE':
                return (
                    <section>
                        <p>Your team administrator will provide you with a <strong>Redemption Code</strong> to sign up.</p>
                        <p>Use this <strong>Redemption Code</strong> in the App Store to install the software.</p>
                    </section>
                );
            case 'CORPORATE_MDM':
                return (
                    <section>
                        <p>Please complete the following steps:</p>
                        <ol>
                            <li>Open your <strong>{this.state.messageAppName}</strong> app</li>
                            <li>Search for "<strong>TRUCE</strong>"</li>
                            <li>Install the <strong>TRUCE Software</strong> app</li>
                        </ol>
                    </section>
                );
            case null:
                return (
                    <section>
                        <p>Do you have the <strong>{this.state.messageAppName}</strong> installed on your device?</p>
                        <div>
                            <button onClick={this.onCorporateMdmYes}>Yes</button>
                            <button onClick={this.onCorporateMdmNo}>No</button>
                        </div>
                    </section>
                );
            default:
                window.dispatchEvent(new CustomEvent('error', { detail: 'Internal error.' }));
                return null;
        }
    }

    onCorporateMdmYes = () => {
        this.setState({visibilityOverride: 'CORPORATE_MDM'});
    };

    onCorporateMdmNo = () => {
        if (this.props.response.isSideLoad && this.props.response.isCorporateMdm) {
            this.setState({visibilityOverride: 'SIDE_LOAD'});
        } else {
            this.setState({visibilityOverride: 'TRUCE_MDM'});
        }
    }
}
