import React from 'react';
import ReactDOM from 'react-dom';
import AlertBar from './app/AlertBar';
import PhoneInputForm from './app/PhoneInputForm';
import Body from './app/Body';

import './index.css';

const refs = {button: null, body: null, phoneInput: null}

ReactDOM.render(<AlertBar />, document.getElementById('alert-bar'));
ReactDOM.render(<Body refs={refs} />, document.getElementById('container'));
ReactDOM.render(<PhoneInputForm refs={refs} />, document.getElementById('header_container'));
