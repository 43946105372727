import React from 'react';

export default class AlertBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorShown: false,
            errorMessage: null,
        };

        this.closeAlertBox = this.closeAlertBox.bind(this);
    }

    closeAlertBox(event) {
        this.setState({errorShown: false});
    }

    componentDidMount() {
        window.addEventListener('error', this.updateError.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('error', this.updateError.bind(this));
    }

    updateError(event) {
        this.setState({
            errorShown: true,
            errorMessage: event.detail,
        });
    }

    render() {
        if (this.state.errorShown) {
            return (
                <div role="alert">
                    <button aria-label="Close" onClick={this.closeAlertBox}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <span>
                        <strong>Error</strong>: {this.state.errorMessage}
                    </span>
                </div>
            );
        }
        return null;
    }
}
